import Vue from 'vue';
import Router from 'vue-router';

function loadPage(page) {
  return () => import(/* webpackChunkName: "page-[request]" */ `@/pages/${page}.vue`);
}

const routes = [
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: loadPage('terms-of-service'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: loadPage('privacy-policy'),
  },
  {
    path: '/',
    name: 'AuthPage',
    redirect: '/sign-up',
    component: loadPage('auth-page'),
    children: [
      {
        path: '/sign-in',
        name: 'SignIn',
        component: loadPage('sign-in'),
      },
      {
        path: '/sign-up',
        name: 'SignUp',
        component: loadPage('sign-up'),
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: loadPage('forgot-password'),
      },
      {
        path: '/password-reset',
        name: 'PasswordReset',
        component: loadPage('password-reset'),
      }
    ]
  }
];

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: routes
});

export default router;
