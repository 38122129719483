<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>


export default {
  name: 'App',
  data() {
    return {}
  },
  created() {
    // VIEWPORT HACK
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    window.dispatchEvent(new Event('resize'));
  }
}
</script>

<style lang="scss">
  @import '~bootstrap/scss/bootstrap.scss';
  :root {
    --bs-primary: #DC2631;
  }

  @font-face {
    font-family: 'Lato';
    src: url('~@/assets/fonts/Lato-Medium.ttf') format('truetype');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Lato';
    src: url('~@/assets/fonts/Lato-SemiBold.ttf') format('truetype');
    font-weight: 600;
  }
  @font-face {
    font-family: 'Lato';
    src: url('~@/assets/fonts/Lato-Bold.ttf') format('truetype');
    font-weight: 700;
  }
  @font-face {
    font-family: 'Lato';
    src: url('~@/assets/fonts/Lato-Black.ttf') format('truetype');
    font-weight: 800;
  }

  body {
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a {
    text-decoration: none;
  }
  button {
    background: none;
    padding: 0;
    border: none;
  }
  .btn {
    transition: all .2s;
    height: 52px;
    border-radius: 52px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.03);
    color: #fff !important;
    &-primary {
      background: var(--bs-primary) !important;
      border-color: var(--bs-primary) !important;
    }
  }
  .btn:hover,
  .btn:focus {
    box-shadow: 0 2px 3px rgba(0,0,0,.3) !important;
    transform: translateY(-2px);
    color: inherit;
  }
  .form-control {
    padding-top: .8rem;
    padding-bottom: .8rem;
    border-color: #E5E7EB;
    box-shadow: 0 2px 1px rgba(0,0,0,.05);
    &:focus {
      box-shadow: none;
      box-shadow: 0 2px 1px rgba(0,0,0,.05);
    }
  }
  .form-check-input {
    margin-right: 8px;
    &:focus {
      box-shadow: none;
      border-color: var(--bs-primary);
    }
    &:checked {
      background-color: var(--bs-primary);
      border-color: var(--bs-primary);
    }
  }
  .fw-sbold {
    font-weight: 600;
  }

  .fadeHeight-enter-active,
  .fadeHeight-leave-active {
    transition: all 0.2s;
    max-height: 500px;
  }
  .fadeHeight-enter,
  .fadeHeight-leave-to
  {
    opacity: 0;
    max-height: 0px;
  }
</style>
