import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index';
import TypeWriter from '@/components/type-writer';

// Phone Flag Code Area
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.config.productionTip = false
Vue.component('TypeWriter', TypeWriter);

Vue.component('vue-phone-number-input', VuePhoneNumberInput);

new Vue({
  render: h => h(App),
  router: router,
}).$mount('#app')
